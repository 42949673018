body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300;400;500&display=swap");

* {
  font-family: "Readex Pro", sans-serif;
}

html {
  height: "100%";
  overflow-x: "hidden";
}

button:disabled,
button[disabled] {
  pointer-events: none;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(0);
  }
  100% {
    transform: none;
  }
}

@keyframes loadingCircleAnimation {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animated-cursor {
  animation: slideAnimation 4s linear infinite;
}

.loading-ico {
  animation: loadingCircleAnimation 1s linear infinite;
}

.rotate-180 {
  transform: rotate(180deg);
}

/* Loader */

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #227ed3;
  border-color: #227ed3 transparent #227ed3 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.outsideClickHandler-container > div:first-of-type {
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.step-icon {
  width: 32px;
  height: 30px;
}

@media screen and (min-width: 834px) {
  .step-icon {
    width: 40px;
    height: 40px;
  }
}

.input {
  font-size: 18px;
  line-height: 24px;
}

@media (min-width: 834px) {
  .input {
    font-size: 20px;
    line-height: 28px;
  }

  .media-br {
    display: none;
  }
}

.swiper-slide > div {
  transition: ease-in-out 0.5s;
  filter: blur(2px);
  opacity: 0.5;
}

.swiper-slide.swiper-slide-active > div {
  transform: none;
  filter: none;
  opacity: 1;
}

.swiper-slide.swiper-slide-active {
  z-index: 1;
}

@media (min-width: 834px) {
  .swiper-slide {
    width: fit-content !important;
  }
  .swiper-slide.swiper-slide-next > div {
    transform: scale(0.6) translateX(-444px);
  }

  .swiper-slide.swiper-slide-prev > div {
    transform: scale(0.6) translateX(444px);
  }
}

#provely-widget {
  left: 10px !important;
}
